import React, { useState } from 'react'
import * as ctaStyles from './common/cta.module.scss'
import * as newsLetterSignupStyles from './newsletterSignup.module.scss'
import axios from 'redaxios'
import { preventWidow } from '../utils/helpers'

const NewsletterSignup = () => {
    const [email, setEmail] = useState('')
    const [response, setResponse] = useState(null)

    const handleSubmit = async (event) => {
        event.preventDefault()
        try {
            const response = await axios.post('/api/createContact', {
                email: email,
            })
            setResponse(response)
        } catch (error) {
            setResponse(error)
        }
    }

    const RenderResponse = () => {
        if (response.status === 200) {
            return (
                <p>
                    <strong>Klart!</strong> Våra kommande nyhetsbrev kommer
                    landa i din inbox, vi hörs!
                </p>
            )
        } else {
            return <p>Något gick tyvärr fel, ladda om sidan och försök igen.</p>
        }
    }

    return (
        <React.Fragment>
            {response && <RenderResponse />}
            {!response && (
                <form
                    onSubmit={handleSubmit}
                    className={newsLetterSignupStyles.newsletterSignup}
                >
                    <h3>{preventWidow('Prenumerera på vårt nyhetsbrev')}</h3>
                    <p>
                        Få vintips, våra senaste artiklar och spännande nyheter
                        från vinvärlden direkt till din mail.{' '}
                    </p>
                    <div className={newsLetterSignupStyles.fieldsWrapper}>
                        <input
                            placeholder="Din emailadress"
                            name="email"
                            type="email"
                            required
                            onChange={(event) => setEmail(event.target.value)}
                            value={email}
                        />
                        <button
                            className={
                                ctaStyles.ctaPrimary +
                                ' ' +
                                ctaStyles.ctaInverted
                            }
                            type="submit"
                        >
                            Prenumerera
                        </button>
                    </div>
                </form>
            )}
        </React.Fragment>
    )
}

export default NewsletterSignup
