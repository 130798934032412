import { Link } from 'gatsby'
import React, { useState, useContext } from 'react'
import { FirebaseContext } from '../firebase'
import { LoginContext } from './layout'
import { SignupContext } from './layout'
import Logo from './logo'
import Cta from '../common/cta'
import * as navigationStyles from './navigation.module.scss'
import { Search as SearchIcon } from 'react-feather'
import SiteSearch from '../search/siteSearch'
import classNames from 'classnames'

const Navigation = () => {
    const [menuVisible, setMenuVisible] = useState(false)

    const { firebase, user, loading } = useContext(FirebaseContext)
    const { loginModal } = useContext(LoginContext)
    const { signupModal } = useContext(SignupContext)

    const hamburgerClick = () => {
        if (menuVisible) {
            document
                .querySelector('html')
                .classList.remove('menu-overlay-visible')
        } else {
            document.querySelector('html').classList.add('menu-overlay-visible')
        }
        setMenuVisible(!menuVisible)
    }

    const navItemClick = () => {
        document.querySelector('html').classList.remove('menu-overlay-visible')
        setMenuVisible(false)
    }

    const handleLogoutClick = () => {
        firebase.logout().then(() => {
            document
                .querySelector('html')
                .classList.remove('menu-overlay-visible')
            setMenuVisible(false)
        })
    }

    const mainNavigationClasses = classNames({
        [navigationStyles.navigation]: true,
        [navigationStyles.navigationOpen]: menuVisible,
    })

    const hamburgerNavigationClasses = classNames({
        [navigationStyles.hamburger]: true,
        [navigationStyles.mobileIcons]: true,
        [navigationStyles.open]: menuVisible,
    })

    return (
        <nav className={mainNavigationClasses}>
            {menuVisible && <div className={navigationStyles.overlay} />}
            <div className={navigationStyles.innerWrapper}>
                <div>
                    <Link
                        to="/"
                        className={navigationStyles.logo}
                        aria-label="Startsida"
                    >
                        <Logo />
                    </Link>
                </div>
                <div
                    className={navigationStyles.mobileIcons}
                    onClick={() => hamburgerClick()}
                    onKeyPress={() => hamburgerClick()}
                    role="button"
                    tabIndex="0"
                    aria-label="Öppna menyn"
                >
                    <SearchIcon className={navigationStyles.searchIcon} />
                    <div className={hamburgerNavigationClasses}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>

                <div className={navigationStyles.linksWapper}>
                    <div className={navigationStyles.mobileSearch}>
                        <SiteSearch
                            forceExpanded={menuVisible}
                            onResultClick={navItemClick}
                        />
                    </div>
                    <Link
                        className={navigationStyles.link}
                        activeClassName={navigationStyles.linkActive}
                        to="/vinskola"
                        onClick={() => navItemClick()}
                    >
                        Vinskola
                    </Link>
                    <Link
                        className={navigationStyles.link}
                        activeClassName={navigationStyles.linkActive}
                        to="/viner"
                        onClick={() => navItemClick()}
                    >
                        Viner
                    </Link>
                    <Link
                        className={navigationStyles.link}
                        activeClassName={navigationStyles.linkActive}
                        to="/vintermer"
                        onClick={() => navItemClick()}
                    >
                        Vintermer
                    </Link>
                    <Link
                        className={navigationStyles.link}
                        activeClassName={navigationStyles.linkActive}
                        to="/artiklar"
                        onClick={() => navItemClick()}
                    >
                        Artiklar
                    </Link>
                    <Link
                        className={navigationStyles.link}
                        activeClassName={navigationStyles.linkActive}
                        to="/quiz"
                        onClick={() => navItemClick()}
                    >
                        Quiz
                    </Link>
                    {!loading && !user && (
                        <span className={navigationStyles.link}>
                            <Cta
                                isLink={false}
                                isBasic={true}
                                isInverted={true}
                                text="Logga in"
                                className={navigationStyles.link}
                                onClick={() => loginModal.set(true)}
                            />
                        </span>
                    )}
                    {!loading && !user && (
                        <Cta
                            text="Bli medlem"
                            isLink={false}
                            isInverted={true}
                            onClick={() => signupModal.set(true)}
                        />
                    )}
                    {!loading && !!user && (
                        <Cta
                            text="Logga ut"
                            isLink={false}
                            isInverted={true}
                            onClick={handleLogoutClick}
                        />
                    )}
                    <div className={navigationStyles.desktopSearch}>
                        <SiteSearch />
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navigation
