import React from 'react'
import { SpinnerCircular } from 'spinners-react'
import * as loaderStyles from './loader.module.scss'
import PropTypes from 'prop-types'

const Loader = ({ text }) => {
    return (
        <div className={loaderStyles.loaderWrapper}>
            {text && <p>{text}</p>}
            <SpinnerCircular color="#e07a5f" size={45} />
        </div>
    )
}

Loader.propTypes = {
    text: PropTypes.string,
}

export default Loader
