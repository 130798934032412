exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artiklar-js": () => import("./../../../src/pages/artiklar.js" /* webpackChunkName: "component---src-pages-artiklar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kategorier-js": () => import("./../../../src/pages/kategorier.js" /* webpackChunkName: "component---src-pages-kategorier-js" */),
  "component---src-pages-quiz-js": () => import("./../../../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-viner-js": () => import("./../../../src/pages/viner.js" /* webpackChunkName: "component---src-pages-viner-js" */),
  "component---src-pages-vinskola-js": () => import("./../../../src/pages/vinskola.js" /* webpackChunkName: "component---src-pages-vinskola-js" */),
  "component---src-pages-vintermer-js": () => import("./../../../src/pages/vintermer.js" /* webpackChunkName: "component---src-pages-vintermer-js" */),
  "component---src-templates-article-category-js": () => import("./../../../src/templates/articleCategory.js" /* webpackChunkName: "component---src-templates-article-category-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-class-js": () => import("./../../../src/templates/class.js" /* webpackChunkName: "component---src-templates-class-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-quiz-js": () => import("./../../../src/templates/quiz.js" /* webpackChunkName: "component---src-templates-quiz-js" */),
  "component---src-templates-wine-country-js": () => import("./../../../src/templates/wineCountry.js" /* webpackChunkName: "component---src-templates-wine-country-js" */),
  "component---src-templates-wine-js": () => import("./../../../src/templates/wine.js" /* webpackChunkName: "component---src-templates-wine-js" */),
  "component---src-templates-wine-region-js": () => import("./../../../src/templates/wineRegion.js" /* webpackChunkName: "component---src-templates-wine-region-js" */),
  "component---src-templates-wine-term-js": () => import("./../../../src/templates/wineTerm.js" /* webpackChunkName: "component---src-templates-wine-term-js" */)
}

