import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import * as ctaStyles from './cta.module.scss'
import { SpinnerCircular } from 'spinners-react'
import classNames from 'classnames'

const Cta = ({
    to,
    onClick,
    text,
    isLink,
    isInverted,
    isBasic,
    isSmall,
    targetBlank,
    isLoading,
}) => {
    const buttonClasses = classNames({
        [ctaStyles.cta]: true,
        [ctaStyles.ctaPrimary]: !isBasic,
        [ctaStyles.ctaInverted]: isInverted && !isBasic,
        [ctaStyles.ctaSmall]: isSmall,
        [ctaStyles.ctaBasic]: isBasic,
        [ctaStyles.ctaInvertedBasic]: isBasic && isInverted,
    })

    if (targetBlank) {
        return (
            <a
                className={buttonClasses}
                rel="noreferrer"
                target="_blank"
                href={to}
            >
                {text}
            </a>
        )
    }
    if (isLink) {
        return (
            <Link className={buttonClasses} to={to}>
                {text}
            </Link>
        )
    }
    if (isBasic) {
        return (
            <button
                className={buttonClasses}
                onClick={onClick}
                onKeyDown={onClick}
            >
                {text}
            </button>
        )
    }
    if (isLoading) {
        return (
            <button
                className={buttonClasses}
                disabled={true}
                aria-label="Laddar"
            >
                <SpinnerCircular color="#e07a5f" size={27} />
            </button>
        )
    }
    return (
        <button className={buttonClasses} onClick={onClick} onKeyDown={onClick}>
            {text}
        </button>
    )
}

Cta.propTypes = {
    to: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
    isLink: PropTypes.bool.isRequired,
    isInverted: PropTypes.bool,
    isBasic: PropTypes.bool,
    isSmall: PropTypes.bool,
    targetBlank: PropTypes.bool,
    isLoading: PropTypes.bool,
}

export default Cta
