import React, { useEffect, useState } from 'react'
import { Modal } from '../common'
import Cta from '../common/cta'
import Cookies from 'universal-cookie'

const AgeVerificationModal = () => {
    const [showModal, setShowModal] = useState('')

    useEffect(() => {
        const cookies = new Cookies()
        const ageVerification = cookies.get('ageVerification')
        setShowModal(!ageVerification)
        if (!ageVerification) {
            document.querySelector('html').style.overflow = 'hidden'
        }
    }, [])

    const handleClick = () => {
        setShowModal(false)
        const oneYearFromNow = new Date()
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)
        const cookie = new Cookies()
        cookie.set('ageVerification', true, {
            path: '/',
            expires: oneYearFromNow,
        })
        document.querySelector('html').style.overflow = 'visible'
    }

    return showModal ? (
        <Modal>
            <h3>Välkommen!</h3>
            <p>
                Denna hemsida riktar sig till personer över 20 år och innehåller
                information om alkoholhaltiga drycker.
            </p>
            <p>Du måste därför ha fyllt 20 år för att gå vidare.</p>
            <div>
                <Cta
                    isInverted={true}
                    isLink={false}
                    onClick={handleClick}
                    text={'Jag har fyllt 20 år'}
                />
                <Cta
                    isInverted={true}
                    isLink={true}
                    to={
                        'https://www.omsystembolaget.se/folkhalsa/unga-och-alkohol/'
                    }
                    text={'Jag är under 20 år'}
                    targetBlank={true}
                />
            </div>
        </Modal>
    ) : null
}

export default AgeVerificationModal
