import { Link } from 'gatsby'
import React from 'react'
import footerImage from '../../images/footer-icon.svg'
import instagram from '../../images/instagram.svg'
import NewsletterSignup from '../newsletterSignup'
import Logo from './logo'
import * as footerStyles from './footer.module.scss'

const Footer = () => {
    const currentYear = new Date().getFullYear()

    return (
        <footer className={footerStyles.footer}>
            <div className={footerStyles.waveWrapper}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path
                        fill="#1B4942"
                        fillOpacity="1"
                        d="M0,96L80,96C160,96,320,96,480,85.3C640,75,800,53,960,64C1120,75,1280,117,1360,138.7L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                    ></path>
                </svg>
            </div>
            <div className={footerStyles.footer__contentWrapper}>
                <div className={footerStyles.footer__content}>
                    <div className={footerStyles.illustration}>
                        <Link to="/" aria-label="Startsida">
                            <img alt="Ikon" src={footerImage} />
                        </Link>
                    </div>
                    <div className={footerStyles.footer__links}>
                        <a
                            href="https://www.instagram.com/vinologik.se/"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Instagram"
                        >
                            <img alt="Besök oss på Instagram" src={instagram} />
                        </a>
                        <Link className={footerStyles.link} to="/kategorier">
                            Artikelkategorier
                        </Link>
                        <Link className={footerStyles.link} to="/om-vinologik">
                            Om Vinologik
                        </Link>
                        <NewsletterSignup />
                    </div>
                </div>
                <div className={footerStyles.copyrightWrapper}>
                    <Link
                        to="/"
                        className={footerStyles.logo}
                        aria-label="Startsida"
                    >
                        <Logo />
                    </Link>
                    <p className={footerStyles.fineprint}>
                        Vinologik har inget samarbete med Systembolaget.
                    </p>
                    <p className={footerStyles.fineprint}>
                        Copyright © Vinologik {currentYear}
                        {/* <Link to="/anvandarvillkor">Användarvillkor.</Link> */}
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
