import translations from './translations.json'

export function debounce(func, wait, immediate) {
    let timeout
    return function () {
        let context = this,
            args = arguments
        let later = function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }
        let callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}

export const getCurrentUrl = () => {
    if (typeof window !== 'undefined') {
        return window.location.href
    }
}

export const toLocaleDateString = (date) => {
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }
    const newDate = new Date(date)
    return newDate.toLocaleDateString('SV', options)
}

export const toLocaleTimeString = (date) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    }
    const newDate = new Date(date)
    return newDate.toLocaleTimeString('SV', options)
}

export const translateErrorMessage = (error) => {
    if (translations.hasOwnProperty(error.code)) {
        return translations[error.code]
    } else {
        console.warn(error)
        return error.message
    }
}

export const getUniqueValuesFromArray = (attribute, array) => {
    if (Array.isArray(array) && array.length > 1) {
        const arrayWithDuplicates = array.map((element) => element[attribute])
        return [...new Set(arrayWithDuplicates.flat())].sort()
    }
}

export const preventWidow = (string) => {
    return string.replace(/\s([^\s<]+)\s*$/, '\u00A0$1')
}
