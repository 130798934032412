import { useEffect, useState } from 'react'
import getFirebaseInstance from './firebase'

const useAuth = () => {
    const [user, setUser] = useState(null)
    const [firebase, setFirebase] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let unsubscribe
        let publicProfileUnsubscribe

        const firebaseInstance = getFirebaseInstance()
        firebaseInstance.auth.languageCode = 'sv'
        setFirebase(firebaseInstance)

        unsubscribe = firebaseInstance.auth.onAuthStateChanged((userResult) => {
            if (userResult) {
                firebaseInstance
                    .getUserProfile({
                        userId: userResult.uid,
                    })
                    .then((result) => {
                        firebaseInstance.auth.currentUser
                            .getIdToken(false)
                            .then((idToken) => {
                                setUser({
                                    ...userResult,
                                    username: result.empty
                                        ? null
                                        : result.docs[0].id,
                                    idToken: idToken,
                                    lastSignInTime:
                                        userResult.metadata.lastSignInTime,
                                })
                                setLoading(false)
                            })
                    })
            } else {
                setUser(null)
                setLoading(false)
            }
        })

        return () => {
            if (unsubscribe) {
                unsubscribe()
            }

            if (publicProfileUnsubscribe) {
                publicProfileUnsubscribe()
            }
        }
    }, [])

    return { user, firebase, loading }
}

export default useAuth
