import firebaseConfig from './config'
import axios from 'redaxios'
import { initializeApp } from 'firebase/app'
import { getPerformance } from 'firebase/performance'
import {
    getAuth,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from 'firebase/auth'
import {
    getFirestore,
    getDocs,
    collection,
    query,
    where,
    orderBy,
    onSnapshot,
} from 'firebase/firestore'

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Performance Monitoring and get a reference to the service
const isBrowser = () => typeof window !== 'undefined'
isBrowser() && getPerformance(app)

class Firebase {
    constructor() {
        if (!firebaseInstance) {
            this.auth = getAuth(app)
            this.db = getFirestore(app)
        }
    }

    async getUserProfile({ userId }) {
        return getDocs(
            query(
                collection(this.db, 'publicProfiles'),
                where('userId', '==', userId)
            )
        )
    }

    async getUserQuizScores({ username }) {
        return getDocs(
            query(
                collection(this.db, 'publicProfiles', username, 'quizResults')
            )
        )
    }

    async register({ email, password, username }) {
        return axios
            .post('/api/createUser', {
                username: username,
                password: password,
                email: email,
            })
            .then(() => {
                signInWithEmailAndPassword(this.auth, email, password)
            })
    }

    async login({ email, password }) {
        return signInWithEmailAndPassword(this.auth, email, password)
    }

    async sendPasswordReset({ email }) {
        return sendPasswordResetEmail(this.auth, email)
    }

    async logout() {
        return await signOut(this.auth)
    }

    async postComment({ comment, contentfulPageId, idToken }) {
        return await axios.post('/api/postComment', {
            comment: comment,
            contentfulPageId: contentfulPageId,
            idToken: idToken,
        })
    }

    async postQuizResult({
        score,
        numberOfQuestions,
        contentfulQuizId,
        idToken,
    }) {
        return await axios.post('/api/postQuizResult', {
            score: score,
            numberOfQuestions: numberOfQuestions,
            contentfulQuizId: contentfulQuizId,
            idToken: idToken,
        })
    }

    subscribeToPageComments({ contentfulPageId, onSnapshotCallback }) {
        const q = query(
            collection(this.db, 'comments'),
            where('contentfulPageId', '==', contentfulPageId),
            orderBy('dateCreated', 'desc')
        )
        return onSnapshot(q, onSnapshotCallback)
    }
}

let firebaseInstance

const getFirebaseInstance = () => {
    if (!firebaseInstance) {
        firebaseInstance = new Firebase()
        return firebaseInstance
    } else if (firebaseInstance) {
        return firebaseInstance
    } else {
        return null
    }
}

export default getFirebaseInstance
