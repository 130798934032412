/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Include Global Styling Sheet
require('./src/styles/global.scss')

const React = require('react')
const Layout = require('./src/components/layout/layout').default

exports.wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>
}
