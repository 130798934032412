import React from 'react'
import PropTypes from 'prop-types'
import * as inputStyles from './input.module.scss'
import classNames from 'classnames'

const Input = ({
    value,
    name,
    onChange,
    placeholder,
    type,
    required,
    inverted,
    minLength,
    autoComplete,
}) => {
    const inputClasses = classNames({
        [inputStyles.input]: true,
        [inputStyles.inverted]: inverted,
    })

    return (
        <input
            className={inputClasses}
            value={value}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            type={type}
            required={required}
            minLength={minLength}
            autoComplete={autoComplete}
        />
    )
}

Input.propTypes = {
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    required: PropTypes.bool,
    inverted: PropTypes.bool,
    minLength: PropTypes.number,
    autoComplete: PropTypes.string,
}

export default Input
