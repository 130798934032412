// extracted by mini-css-extract-plugin
export var desktopSearch = "navigation-module--desktopSearch--72e4b";
export var fineprint = "navigation-module--fineprint--c9e3f";
export var hamburger = "navigation-module--hamburger--21961";
export var innerWrapper = "navigation-module--innerWrapper--11f58";
export var link = "navigation-module--link--97c74";
export var linkActive = "navigation-module--linkActive--f3245";
export var linksWapper = "navigation-module--linksWapper--9addc";
export var logo = "navigation-module--logo--dfe8f";
export var mobileIcons = "navigation-module--mobileIcons--8ea15";
export var mobileSearch = "navigation-module--mobileSearch--8c800";
export var navigation = "navigation-module--navigation--a84bc";
export var navigationOpen = "navigation-module--navigationOpen--f74e9";
export var navigationScrolled = "navigation-module--navigationScrolled--6fb63";
export var newsLetterWrapper = "navigation-module--newsLetterWrapper--65070";
export var open = "navigation-module--open--fc615";
export var overlay = "navigation-module--overlay--0f6ba";
export var searchIcon = "navigation-module--searchIcon--de82b";