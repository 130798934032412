import React, { useContext, useState } from 'react'
import { ErrorMessage, Form, Input, Modal, Loader } from '../common'
import { FirebaseContext } from '../firebase'
import { translateErrorMessage } from '../../utils/helpers'
import { LoginContext } from '../layout/layout'
import { SignupContext } from '../layout/layout'
import Cta from '../common/cta'

const LoginModal = () => {
    const [formValues, setFormValues] = useState({ email: '', password: '' })
    const [resetPassword, setResetPassword] = useState(false)
    const [sentPassword, setSentPassword] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const { firebase } = useContext(FirebaseContext)
    const { loginModal } = useContext(LoginContext)
    const { signupModal } = useContext(SignupContext)

    const handleInputChange = (event) => {
        event.persist()
        setErrorMessage('')
        setFormValues((currentValues) => ({
            ...currentValues,
            [event.target.name]: event.target.value,
        }))
    }

    const handleLoginSubmit = (event) => {
        event.preventDefault()
        setLoading(true)
        firebase
            .login({ email: formValues.email, password: formValues.password })
            .then(() => {
                setLoading(false)
                loginModal.set(false)
            })
            .catch((error) => {
                setErrorMessage(translateErrorMessage(error))
                setLoading(false)
            })
    }

    const handleForgotPasswordSubmit = (event) => {
        event.preventDefault()
        firebase
            .sendPasswordReset({ email: formValues.email })
            .then(() => {
                setSentPassword(true)
            })
            .catch((error) => {
                setErrorMessage(translateErrorMessage(error))
            })
    }

    const renderForgotPasswordForm = () => {
        return (
            <Form onSubmit={handleForgotPasswordSubmit}>
                <h3>Återställ lösenord</h3>
                <p>Glömt ditt lösenord? Inga problem!</p>
                <p>Fyll i din email så skickar vi ett nytt till dig.</p>
                <Input
                    inverted={false}
                    value={formValues.email}
                    name="email"
                    onChange={handleInputChange}
                    placeholder="email"
                    type="email"
                    required={true}
                    autoComplete={'email'}
                />
                {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                {!!loading && <Loader />}
                {sentPassword && (
                    <p>Klart! Kolla din mail för mer information.</p>
                )}
                <div>
                    <Cta
                        text="Återställ lösenord"
                        isLink={false}
                        onClick={null}
                        isInverted={true}
                    />
                    <Cta
                        isLink={false}
                        isBasic={true}
                        isSmall={true}
                        text="Tillbaka"
                        onClick={(event) => {
                            event.preventDefault()
                            setResetPassword(!resetPassword)
                            setSentPassword(false)
                            setErrorMessage('')
                        }}
                    />
                </div>
            </Form>
        )
    }

    const renderLoginForm = () => {
        return (
            <Form onSubmit={handleLoginSubmit}>
                <h3>Logga in</h3>
                <p>Välkommen till Vinologik!</p>
                <Input
                    inverted={false}
                    value={formValues.email}
                    name="email"
                    onChange={handleInputChange}
                    placeholder="e-post"
                    type="email"
                    required={true}
                    autoComplete={'email'}
                />
                <Input
                    inverted={false}
                    value={formValues.password}
                    name="password"
                    onChange={handleInputChange}
                    placeholder="lösenord"
                    type="password"
                    required={true}
                    autoComplete={'current-password'}
                />
                {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                {!!loading && <Loader />}
                <div>
                    <Cta
                        text="Logga in"
                        isLink={false}
                        onClick={null}
                        isInverted={true}
                    />
                    <Cta
                        isLink={false}
                        isBasic={true}
                        isSmall={true}
                        isInverted={true}
                        text="Glömt ditt lösenord?"
                        onClick={(event) => {
                            event.preventDefault()
                            setResetPassword(!resetPassword)
                            setErrorMessage('')
                        }}
                    />
                    <br />
                    <Cta
                        isLink={false}
                        isBasic={true}
                        isSmall={true}
                        isInverted={true}
                        text="Bli medlem"
                        onClick={(event) => {
                            event.preventDefault()
                            loginModal.set(false)
                            signupModal.set(true)
                        }}
                    />
                </div>
            </Form>
        )
    }

    return (
        <Modal onCloseClick={() => loginModal.set(false)}>
            {resetPassword ? renderForgotPasswordForm() : renderLoginForm()}
        </Modal>
    )
}

export default LoginModal
