import React from 'react'
import { Modal } from '../common'
import Cta from '../common/cta'
import PropTypes from 'prop-types'

const WelcomeModal = ({ onCloseClick }) => {
    return (
        <Modal onCloseClick={onCloseClick}>
            <h3>Varmt välkommen!</h3>
            <p>
                Eftersom det är första gången du loggar in vill vi hälsa dig
                lite extra välkommen till Vinologik!
            </p>
            <p>
                Vi tycker det är superkul att du hittat hit och vi hoppas vi kan
                få ditt vinintresse att växa ännu större.
            </p>
            <p>
                Har du några förslag på förbättringar eller vill att vi ska
                skriva om något speciellt är det bara att höra av sig, vi finns
                på kontakt@vinologik.se.
            </p>
            <p>Ha det bäst!</p>
            <p>Christian & Cornelia på Vinologik</p>
            <Cta
                isInverted={true}
                isLink={false}
                onClick={onCloseClick}
                text={'Tack, nu kör vi!'}
            />
        </Modal>
    )
}

WelcomeModal.propTypes = {
    onCloseClick: PropTypes.func,
}

export default WelcomeModal
