const firebaseConfig = {
    apiKey: 'AIzaSyBqCz49xSogfBPWxr17wDjg1pcvKSzBvi0',
    authDomain: 'vinologik-e6272.firebaseapp.com',
    databaseURL: 'https://vinologik-e6272.firebaseio.com',
    projectId: 'vinologik-e6272',
    storageBucket: 'vinologik-e6272.appspot.com',
    messagingSenderId: '848444040144',
    appId: '1:848444040144:web:1ad3be24cd798653cd4fd5',
}

export default firebaseConfig

// The content of the Firebase config file or object is considered public.
