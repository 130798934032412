import React, { useState, useEffect } from 'react'
import { useFlexSearch } from 'react-use-flexsearch'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as searchbarStyles from './searchbar.module.scss'

const SearchBar = ({ isExpanded, onClick }) => {
    const data = useStaticQuery(graphql`
        query {
            localSearchPages {
                store
                index
                publicIndexURL
                publicStoreURL
            }
        }
    `)

    const index = data.localSearchPages.index
    const store = data.localSearchPages.store

    const [query, setQuery] = useState('')
    const results = useFlexSearch(query, index, store)

    const handleInputChange = (event) => {
        setQuery(event.target.value)
    }

    const onResultClick = (event) => {
        setQuery('')
        onClick()
    }

    useEffect(() => {
        setQuery('')
    }, [isExpanded])

    return (
        <div className={searchbarStyles.wrapper}>
            <input
                placeholder="Sök..."
                name="search"
                type="text"
                onChange={handleInputChange}
                value={query}
            />
            {query && isExpanded && (
                <div className={searchbarStyles.resultsWrapper}>
                    <h3>Resultat</h3>
                    <p>Hittade {results.length} träffar.</p>
                    {results.map((result) => (
                        <Link
                            to={result.slug}
                            key={result.id}
                            className={searchbarStyles.resultWrapper}
                            onClick={onResultClick}
                        >
                            <div className={searchbarStyles.imageWrapper}>
                                {result.image && (
                                    <GatsbyImage
                                        image={result.image.gatsbyImageData}
                                        alt={result.title}
                                    />
                                )}
                            </div>

                            <div className={searchbarStyles.textWrapper}>
                                <h4 className={searchbarStyles.title}>
                                    {result.title}
                                </h4>
                                {result.subtitle && (
                                    <p className="fineprint">
                                        {result.subtitle}
                                    </p>
                                )}
                                {result.plainDescription && (
                                    <p className="fineprint">
                                        {result.plainDescription}
                                    </p>
                                )}
                                {result.description && (
                                    <p className="fineprint">
                                        {result.description}
                                    </p>
                                )}
                                {result.explanation && (
                                    <p className="fineprint">
                                        Klicka här för att lära dig mer om
                                        vintermen {result.title.trim()}.
                                    </p>
                                )}
                            </div>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    )
}

export default SearchBar
