import React, { useState, useEffect } from 'react'
import SearchBar from '../search/searchbar'
import { Search as SearchIcon } from 'react-feather'
import * as siteSearchStyles from './siteSearch.module.scss'

const SiteSearch = ({ forceExpanded = false, onResultClick = () => {} }) => {
    const [searchExpanded, setSearchExpanded] = useState(forceExpanded)

    const toggleSearchInput = () => {
        onResultClick()
        if (forceExpanded) {
            return null
        } else {
            setSearchExpanded(!searchExpanded)
        }
    }

    useEffect(() => {
        setSearchExpanded(forceExpanded)
    }, [forceExpanded])

    return (
        <div
            className={`${siteSearchStyles.wrapper} ${
                searchExpanded ? `${siteSearchStyles.searchExpanded}` : ''
            }`}
        >
            <SearchBar
                isExpanded={searchExpanded}
                onClick={toggleSearchInput}
            />
            <SearchIcon
                className={siteSearchStyles.desktopSearchIcon}
                onClick={toggleSearchInput}
            />
        </div>
    )
}

export default SiteSearch
