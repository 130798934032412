import React from 'react'
import PropTypes from 'prop-types'
import * as errorMessageStyles from './errorMessage.module.scss'

const ErrorMessage = ({ children }) => {
    return <p className={errorMessageStyles.errorMessage}>{children}</p>
}

ErrorMessage.propTypes = {
    children: PropTypes.node.isRequired,
}

export default ErrorMessage
