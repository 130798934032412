import React, { useContext, useState } from 'react'
import { ErrorMessage, Form, Input, Modal, Loader } from '../common'
import { FirebaseContext } from '../firebase'
import { SignupContext } from '../layout/layout'
import { LoginContext } from '../layout/layout'
import Cta from '../common/cta'
import WelcomeModal from './welcomeModal'

const SignupModal = () => {
    const PASSWORD_MIN_LENGTH = 6

    const [formValues, setFormValues] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
    })
    const [errorMessage, setErrorMessage] = useState('')

    const [loading, setLoading] = useState(false)

    const [showWelcomeModal, setShowWelcomeModal] = useState(false)

    const { firebase } = useContext(FirebaseContext)

    const { signupModal } = useContext(SignupContext)

    const { loginModal } = useContext(LoginContext)

    const handleInputChange = (event) => {
        event.persist()
        setErrorMessage('')
        setFormValues((currentValues) => ({
            ...currentValues,
            [event.target.name]: event.target.value,
        }))
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (formValues.password === formValues.confirmPassword) {
            setLoading(true)
            setErrorMessage('')
            firebase
                .register({
                    username: formValues.username,
                    email: formValues.email,
                    password: formValues.password,
                })
                .then(() => {
                    setLoading(false)
                    setShowWelcomeModal(true)
                })
                .catch((error) => {
                    setLoading(false)
                    setErrorMessage(error.data.error.message)
                })
        } else {
            setErrorMessage('Lösenorden stämmer inte överens, försök igen.')
        }
    }

    if (showWelcomeModal) {
        return <WelcomeModal onCloseClick={() => signupModal.set(false)} />
    } else {
        return (
            <Modal onCloseClick={() => signupModal.set(false)}>
                <Form onSubmit={handleSubmit}>
                    <h3>Bli medlem</h3>
                    <p>
                        Som medlem kan du bland annat kommentera och så sparas
                        dina resultat i våra quiz.
                    </p>
                    <Input
                        inverted={false}
                        value={formValues.username}
                        name="username"
                        onChange={handleInputChange}
                        placeholder="användarnamn"
                        type="text"
                        required={true}
                        autoComplete="username"
                    />
                    <Input
                        inverted={false}
                        value={formValues.email}
                        name="email"
                        onChange={handleInputChange}
                        placeholder="e-post"
                        type="email"
                        required={true}
                        autoComplete="email"
                    />
                    <Input
                        inverted={false}
                        value={formValues.password}
                        name="password"
                        onChange={handleInputChange}
                        placeholder="lösenord"
                        type="password"
                        required={true}
                        minLength={PASSWORD_MIN_LENGTH}
                        autoComplete="new-password"
                    />
                    <Input
                        inverted={false}
                        value={formValues.confirmPassword}
                        name="confirmPassword"
                        onChange={handleInputChange}
                        placeholder="bekräfta lösenord"
                        type="password"
                        required={true}
                        minLength={PASSWORD_MIN_LENGTH}
                        autoComplete="new-password"
                    />
                    {/* <p className="fineprint">
                        Genom att registrera dig godkänner du{' '}
                        <a
                            className="white underline"
                            href="/anvandarvillkor"
                            target="_blank"
                        >
                            användarvillkoren.
                        </a>
                    </p> */}

                    {!!errorMessage && (
                        <ErrorMessage>{errorMessage}</ErrorMessage>
                    )}
                    {!!loading && <Loader />}
                    <div>
                        <Cta
                            text="Bli medlem"
                            isLink={false}
                            onClick={null}
                            isInverted={true}
                        />
                        <Cta
                            isLink={false}
                            isBasic={true}
                            isSmall={true}
                            isInverted={true}
                            text="Redan medlem?"
                            onClick={(event) => {
                                event.preventDefault()
                                signupModal.set(false)
                                loginModal.set(true)
                            }}
                        />
                    </div>
                </Form>
            </Modal>
        )
    }
}

export default SignupModal
