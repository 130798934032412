import React from 'react'
import Logo from '../layout/logo'
import { X } from 'react-feather'
import * as modalStyles from './modal.module.scss'
import PropTypes from 'prop-types'

const Modal = ({ children, onCloseClick }) => {
    return (
        <div className={modalStyles.modalWrapper}>
            <div className={modalStyles.modalContent}>
                {onCloseClick && (
                    <X
                        color={'#fff'}
                        size={32}
                        className={modalStyles.closeIcon}
                        onClick={onCloseClick}
                    />
                )}
                <div className={modalStyles.logo}>
                    <Logo />
                </div>
                {children}
            </div>
        </div>
    )
}

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    onCloseClick: PropTypes.func,
}

export default Modal
