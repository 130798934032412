import React from 'react'
import PropTypes from 'prop-types'
import * as formStyles from './form.module.scss'

const Form = ({ children, onSubmit }) => {
    return (
        <form className={formStyles.formWrapper} onSubmit={onSubmit}>
            {children}
        </form>
    )
}

Form.propTypes = {
    onSubmit: PropTypes.func.isRequired,
}

export default Form
