// extracted by mini-css-extract-plugin
export var copyrightWrapper = "footer-module--copyrightWrapper--68c3d";
export var fineprint = "footer-module--fineprint--df2b7";
export var footer = "footer-module--footer--d155e";
export var footer__content = "footer-module--footer__content--1c4ff";
export var footer__contentWrapper = "footer-module--footer__contentWrapper--199e0";
export var footer__links = "footer-module--footer__links--b7120";
export var illustration = "footer-module--illustration--45434";
export var link = "footer-module--link--06b40";
export var linkActive = "footer-module--linkActive--313fe";
export var logo = "footer-module--logo--4822d";
export var waveWrapper = "footer-module--waveWrapper--fae0d";