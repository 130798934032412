import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql, useStaticQuery } from 'gatsby'
import { FirebaseContext, useAuth } from '../firebase'
import Navigation from './navigation'
import Footer from './footer'
import AgeVerificationModal from '../modals/ageVerificationModal'
import LoginModal from '../modals/loginModal'
import SignupModal from '../modals/signupModal'

const Layout = ({ children }) => {
    const [showLogin, setShowLogin] = useState(false)
    const [showSignup, setShowSignup] = useState(false)

    const loginStore = {
        loginModal: { get: showLogin, set: setShowLogin },
    }

    const signupStore = {
        signupModal: { get: showSignup, set: setShowSignup },
    }

    const data = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    siteUrl
                }
            }
            metaImage: file(relativePath: { eq: "images/meta-image.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 2400
                        height: 1260
                        quality: 95
                        placeholder: BLURRED
                        layout: FIXED
                    )
                }
            }
        }
    `)

    const pageURL = `${data.site.siteMetadata.siteUrl}`

    const { user, firebase, loading } = useAuth()

    return (
        <FirebaseContext.Provider value={{ user, firebase, loading }}>
            <LoginContext.Provider value={loginStore}>
                <SignupContext.Provider value={signupStore}>
                    <GatsbySeo
                        openGraph={{
                            images: [
                                {
                                    url:
                                        pageURL +
                                        data.metaImage.childImageSharp
                                            .gatsbyImageData.images.fallback
                                            .src,
                                    width: data.metaImage.childImageSharp
                                        .gatsbyImageData.width,
                                    height: data.metaImage.childImageSharp
                                        .gatsbyImageData.height,
                                    alt: 'Vinologik',
                                },
                            ],
                        }}
                    />
                    <AgeVerificationModal />
                    {showLogin && <LoginModal />}
                    {showSignup && <SignupModal />}
                    <Navigation />
                    <main>{children}</main>
                    <Footer />
                </SignupContext.Provider>
            </LoginContext.Provider>
        </FirebaseContext.Provider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export const LoginContext = createContext({})
export const SignupContext = createContext({})

export default Layout
